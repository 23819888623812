<template>
  <div v-if="companyInfo">
    <div class="px-4 py-5 flex flex-col">
      <div class="clearfix border-b border-secondary-500 pb-2 mb-3">
        <div class="float-left text-lg text-bold font-serif">
          {{ companyInfo.title }}
        </div>
        <div class="float-right">
          <button v-if="user.role == 'admin'" class="btn-blue-outline font-bold" @click="show()">Ändra</button>
        </div>
      </div>
      <div class="flex flex-wrap text-sm">
        <div class="w-full py-2">
          <div v-html="companyInfo.description" class="ProseMirror view"></div>
        </div>
      </div>
    </div>

    <UpdateInternalInfo type="info" :internalInfo="updateInfo" @updateInternalInfo="getCompanyInfo" />
  </div>
</template>

<script>
import UpdateInternalInfo from "@/components/modal/update_internal_info";

export default {
  name: "CompanyInfo",
  title() {
    return `Info | SecurCloud`;
  },
  components: {
    UpdateInternalInfo,
  },
  data() {
    return {
      user: this.$store.state.user,
      companyInfo: null,
      updateInfo: null,
    };
  },
  methods: {
    async getCompanyInfo() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/company-info`);
        this.companyInfo = response.data.data;
      } catch (error) {
        this.handleError(error);
      }
    },

    show() {
      this.updateInfo = _.cloneDeep(this.companyInfo);
      this.$modal.show("info");
    },
  },

  created() {
    this.getCompanyInfo();
    this.setPageTitle(`Info`, "companyInfo");
  },
};
</script>
